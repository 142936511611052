import React, { useState } from "react";
import { Field, useForm } from "react-final-form";
import Select, { components } from "react-select";
import clsx from "clsx";
import { isValidNumber } from "utils/validations";
import { formatPhoneNumber as formatNumber } from "utils/formatters";

// Define a type for the option format
type CountryOption = {
  label: string;
  value: string;
  flag: string;
  code: string;
};

// Define a list of options for the dropdown
const countryOptions: CountryOption[] = [
  { label: "United States", code: "1", value: "US", flag: "🇺🇸" },
  { label: "Australia", code: "61", value: "AU", flag: "🇦🇺" },
  { label: "Brazil", code: "55", value: "BR", flag: "🇧🇷" },
  { label: "Canada", code: "1", value: "CA", flag: "🇨🇦" },
  { label: "China", code: "86", value: "CN", flag: "🇨🇳" },
  { label: "France", code: "33", value: "FR", flag: "🇫🇷" },
  { label: "Germany", code: "49", value: "DE", flag: "🇩🇪" },
  { label: "India", code: "91", value: "IN", flag: "🇮🇳" },
  { label: "Italy", code: "39", value: "IT", flag: "🇮🇹" },
  { label: "Japan", code: "81", value: "JP", flag: "🇯🇵" },
  { label: "Mexico", code: "52", value: "MX", flag: "🇲🇽" },
  { label: "New Zealand", code: "64", value: "NZ", flag: "🇳🇿" },
  { label: "Russia", code: "7", value: "RU", flag: "🇷🇺" },
  { label: "South Africa", code: "27", value: "ZA", flag: "🇿🇦" },
  { label: "Spain", code: "34", value: "ES", flag: "🇪🇸" },
  { label: "United Kingdom", code: "44", value: "GB", flag: "🇬🇧" },
  // And so on for other countries
];

const FlagMenuList = (props: any) => (
  <components.MenuList {...props} className="w-72" />
);

const FlagMenu = (props: any) => (
  <components.Menu {...props} className="w-fit" />
);

const PhoneNumberInput: React.FC<{
  name: string;
  title?: string;
  required?: boolean;
}> = ({ name, title, required }) => {
  const [selectedCountry, setSelectedCountry] = useState<CountryOption>(
    countryOptions[0],
  );
  const { change } = useForm();

  const handleCountryChange = (option: CountryOption | null) => {
    setSelectedCountry(option);
    change("phoneNumber", "");
  };

  const formatPhoneNumber = (value: string) => {
    let cleanValue = value?.replace(/\D/g, "").substring(0, 16);
    if (cleanValue?.length === 0 || !cleanValue) {
      return "+" + selectedCountry.code;
    }
    return `${formatNumber(value, selectedCountry.value, true, true, false)}`
      .replace("ext.", "")
      .trim();
  };

  return (
    <Field
      name={name}
      validate={(value) => {
        return isValidNumber(value || "", selectedCountry.value)
          ? undefined
          : "Invalid phone number";
      }}
      format={formatPhoneNumber}
      render={({ input, meta }) => (
        <div className="flex flex-col gap-2">
          <div
            className={clsx(
              "focus-within:border-mustard flex flex-row flex-wrap rounded-md border-2 bg-transparent px-4 py-2 text-2xl transition-colors duration-200  ease-in-out ",
              meta.error && meta.touched
                ? "border-red-500"
                : "border-caringwire",
            )}
          >
            <Select
              options={countryOptions}
              value={selectedCountry}
              onChange={handleCountryChange}
              formatOptionLabel={(option, { context }) =>
                context === "value"
                  ? option.flag
                  : `${option.flag} ${option.label}`
              }
              components={{
                MenuList: FlagMenuList,
                Menu: FlagMenu,
              }}
              placeholder="Select a country"
              className="mr-4 flex-none rounded-none bg-transparent text-lg transition-colors duration-200 ease-in-out focus:border-blue-500 focus:outline-none"
            />
            <input
              {...input}
              type="tel"
              autoComplete="tel"
              placeholder="+1(123)456-7890"
              className="w-52 flex-auto border-0 outline-none font-light text-caringwire"
            />
          </div>
          {meta.error && meta.touched && (
            <div className="mt-2 w-fit rounded-md border border-red-500 bg-red-100 p-2 text-sm text-red-800">
              <span role="img" aria-label="alert" className="mr-2">
                ⚠️
              </span>
              <span>{meta.error}</span>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default PhoneNumberInput;
