import { Field } from "react-final-form";
import React, { InputHTMLAttributes } from "react";
import clsx from "clsx";

const InputField: React.FC<
  InputHTMLAttributes<HTMLInputElement> & {
    title?: string;
    required?: boolean;
    placeholder?: string;
    format?: (value: string) => string;
  }
> = ({ name, title, format, required, placeholder, ...rest }) => {
  return (
    <Field name={name} format={format}>
      {({ input, meta }) => (
        <div className={clsx("flex flex-col", rest.className)}>
          <input
            placeholder={placeholder}
            {...rest}
            {...input}
            className={clsx(
              "form-input focus:border-mustard text-caringwire rounded-md border-2 bg-transparent px-6 py-4 text-lg font-thin transition-colors duration-200 ease-in-out focus:outline-none",
              meta.error && meta.touched
                ? "border-red-500"
                : "border-caringwire",
            )}
          />
          {meta.error && meta.touched && (
            <div className="mt-2 w-fit rounded-md border border-red-500 bg-red-100 p-2 text-sm text-red-800">
              <span role="img" aria-label="alert" className="mr-2">
                ⚠️
              </span>
              <span>{meta.error}</span>
            </div>
          )}
        </div>
      )}
    </Field>
  );
};

export default InputField;
