import { Form } from "react-final-form";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { FORM_ERROR } from "final-form";
import clsx from "clsx";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { mixpanel } from "utils/mixpanel";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptionsMode, loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

interface FormValues {
  address: string;
  addressLine2: string;
  city: string;
  country: string;
  dateOfBirthDay: string;
  dateOfBirthMonth: string;
  dateOfBirthYear: string;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
  state: string;
  zip: string;
}

export type Props = {
  stripeElementsOptions: StripeElementsOptionsMode;
};

const PaymentForm: React.FC<Props> = ({
  stripeElementsOptions,
}: {
  stripeElementsOptions: StripeElementsOptionsMode;
}) => {
  let navigate = useNavigate();
  const { state } = useLocation();

  const stripe = useStripe();
  const elements = useElements();

  return (
    <Form
      onSubmit={async (values: FormValues) => {
        // only keep the digits and remove whitespace
        const params = {
          ...state,
          ...values,
        };

        mixpanel.track("form-page-viewed", {
          "form-name": "payment",
        });

        mixpanel.track("form-ended", state);

        await elements.submit();

        try {
          const apiCall = await fetch(
            process.env.HAHS_API_URL + "/stripe/create-subscription",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(params),
            }
          );

          const responseJSON = await apiCall.json();
          const { secret, type } = responseJSON;
          const confirmIntent =
            type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;

          const { error } = await confirmIntent({
            elements,
            clientSecret: secret,
            confirmParams: {
              return_url: `${process.env.DOMAIN}/hahs/success`,
            },
          });

          if (error) {
            console.error(error.message);
          }
        } catch (e) {
          console.error(e);
        }
      }}
      validate={(values: FormValues) => {
        const errors: Partial<FormValues> = {};

        return errors;
      }}
      render={({ handleSubmit, submitError, valid }) => (
        <div className="m-8 overflow-clip rounded-3xl bg-white">
          <div className="flex flex-col">
            <div className="flex flex-col content-center justify-center gap-1 bg-white p-10">
              <h1 className="text-caringwireDarkBlue mb-2 text-center text-3xl font-bold">
                Payment Information
              </h1>
              <p className="text-caringwireDarkBlue mb-2 text-center text-xl font-light">
                Great! Now all we need are your payment details and we're good
                to go!
              </p>
            </div>
            <p className="text-caringwireDarkBlue mt-4 text-center text-sm">
              HAHS Subscription is ${stripeElementsOptions.amount / 100} after
              taxes and fees. We never store your card number or CVC number
            </p>
            <div className="p-8 pt-4">
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                {submitError && (
                  <div className="text-red-500">{FORM_ERROR}</div>
                )}

                <PaymentElement className="focus-visible:outline-none" />
                <div className="mt-4 flex items-center justify-start">
                  <button
                    onClick={() => {
                      navigate("/hahs/address", { state: state });
                    }}
                    className="bg-caringwire mr-8 inline-flex items-center px-4 py-2 font-bold text-white hover:bg-blue-700"
                  >
                    <span>←</span>
                  </button>
                  <button
                    type="submit"
                    className={clsx(
                      "bg-caringwire inline-flex w-20 items-center justify-center px-4 py-2 font-bold text-white hover:bg-blue-700",
                      !valid && "opacity-20"
                    )}
                  >
                    <span>OK</span>
                  </button>
                  <span
                    className={clsx("ml-2 text-xs", !valid && "opacity-20")}
                  >
                    ⏎ press <span className="font-bold">Enter</span>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default () => {
  const { state } = useLocation();
  const { priceId } = state;
  const [stripeHahsSubscriptionOptions, setStripeHahsSubscriptionOptions] =
    useState<StripeElementsOptionsMode | null>(null);

  useEffect(() => {
    (async () => {
      const apiCall = await fetch(
        process.env.HAHS_API_URL + `/stripe/price/${priceId}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const priceResponse = await apiCall.json();

      if (!priceResponse.unit_amount) {
        throw new Error("Invalid price response");
      }

      const newOptions: StripeElementsOptionsMode = {
        mode: "subscription",
        amount: priceResponse.unit_amount,
        currency: "usd",
        appearance: {
          theme: "stripe",
          rules: {
            ".Input": {
              border: "2px solid #038fca",
              outline: "none",
              borderRadius: "0.375rem;",
            },
            ".Input:focus": {
              border: "1px solid #C4C4C4",
              outline: "none",
            },
          },
        },
      };
      setStripeHahsSubscriptionOptions(newOptions);
    })();
  }, [priceId]);

  if (!stripeHahsSubscriptionOptions) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-4xl text-center">Loading...</div>
      </div>
    );
  }

  return (
    <Elements stripe={stripePromise} options={stripeHahsSubscriptionOptions}>
      <PaymentForm stripeElementsOptions={stripeHahsSubscriptionOptions} />
    </Elements>
  );
};
