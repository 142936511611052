import { PhoneNumberUtil } from "google-libphonenumber";

export const required = (value: string) =>
  value ? undefined : "Please fill this in";
export const email = (value: string) =>
  value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
    ? undefined
    : "Invalid email";
export const isValidNumber = (number, countryCode) => {
  try {
    var phoneUtil = PhoneNumberUtil.getInstance();
    var numberObj = phoneUtil.parseAndKeepRawInput(number, countryCode);
    return phoneUtil.isValidNumber(numberObj);
  } catch (e) {
    return false;
  }
};
