//Import Mixpanel SDK
import mixpanel from "mixpanel-browser";

// Near entry of your product, init Mixpanel
mixpanel.init(process.env.MIXPANEL_TOKEN, {
  debug: true,
  persistence: "localStorage",
});

export { mixpanel };
