import { Form } from "react-final-form";
import { useNavigate, useLocation } from "react-router-dom";
import React from "react";

import { FORM_ERROR } from "final-form";
import InputField from "components/form/TextInput";
import { required } from "utils/validations";
import clsx from "clsx";

import Select from "react-select";

import optionsJSON from "utils/us-states.json";
import { mixpanel } from "utils/mixpanel";

interface FormValues {
  address: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}
const AddressForm: React.FC = () => {
  let navigate = useNavigate();
  const { state } = useLocation();

  mixpanel.track("form-page-viewed", {
    "form-name": "address",
  });

  const options = optionsJSON.options;

  return (
    <Form
      initialValues={state}
      onSubmit={async (values: FormValues) => {
        const newState = {
          ...state,
          ...values,
        };
        // Form submission logic here
        console.log(newState);
        navigate("/hahs/payment", { state: newState });
      }}
      validate={(values: FormValues) => {
        const errors: Partial<FormValues> = {};

        const addressError = required(values.address);
        errors.address = addressError;

        const cityError = required(values.city);
        errors.city = cityError;

        const stateError = required(values.state);
        errors.state = stateError;

        const zipError = required(values.zip);
        errors.zip = zipError;

        return errors;
      }}
      render={({ handleSubmit, submitError, valid, form }) => (
        <div className="m-8 overflow-clip rounded-3xl bg-white">
          <div className="flex flex-col">
            <div className="flex flex-col content-center justify-center gap-1 bg-white p-10">
              <h1 className="text-caringwireDarkBlue mb-2 text-center text-3xl font-bold">
                Home Address
              </h1>
              <p className="text-caringwireDarkBlue mb-2 text-center text-xl font-light">
                Your address is used to connect you with clinicians who are
                licensed in your state. Your personal information is never sold
                or shared.
              </p>
            </div>
            <div className="p-8">
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <InputField
                  name="address"
                  title="Address"
                  placeholder="Address *"
                  autoComplete="street-address"
                  required
                />

                <InputField
                  name="addressLine2"
                  title="Address Line 2"
                  placeholder="Address Line 2"
                  autoComplete="address-line2"
                />
                <InputField
                  name="city"
                  title="City"
                  placeholder="City"
                  autoComplete="address-level2"
                  required
                />
                <Select
                  name="state"
                  placeholder="State"
                  options={options}
                  className="react-select-container min-w-40 max-w-64 form-input focus:border-mustard text-caringwire rounded-md bg-transparent text-2xl font-thin transition-colors duration-200 ease-in-out focus:outline-none"
                  required
                  onChange={(option) => {
                    form.change("state", option.value);
                  }}
                />

                <InputField
                  name="zip"
                  title="Zip"
                  placeholder="Zip"
                  autoComplete="postal-code"
                  required
                />

                {submitError && (
                  <div className="text-red-500">{FORM_ERROR}</div>
                )}
                <div className="mt-4 flex items-center justify-start">
                  <button
                    onClick={() => {
                      // print all the values
                      console.log(state);
                      navigate("/hahs/dob", {
                        state: {
                          ...state,
                          country: "United States",
                        },
                      });
                    }}
                    className="bg-caringwire mr-8 inline-flex items-center px-4 py-2 font-bold text-white hover:bg-blue-700"
                  >
                    <span>←</span>
                  </button>
                  <button
                    type="submit"
                    className={clsx(
                      "bg-caringwire inline-flex w-20 items-center justify-center px-4 py-2 font-bold text-white hover:bg-blue-700",
                      !valid && "opacity-20",
                    )}
                  >
                    <span>OK</span>
                  </button>
                  <span
                    className={clsx("ml-2 text-xs", !valid && "opacity-20")}
                  >
                    ⏎ press <span className="font-bold">Enter</span>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default AddressForm;
