import { Form } from "react-final-form";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import React from "react";

import { FORM_ERROR } from "final-form";
import PhoneNumberInput from "components/form/PhoneNumberInput";
import InputField from "components/form/TextInput";
import { required, email } from "utils/validations";
import clsx from "clsx";
import { mixpanel } from "utils/mixpanel";

interface FormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

const InfoForm: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();

  const params = new URLSearchParams(searchParams);
  const orgId = params.get("org_id");
  const priceId = params.get("stripe_price_id");

  return (
    <Form
      initialValues={state}
      onSubmit={async (values: FormValues) => {
        // only keep the digits and remove whitespace
        const phoneNumber = `+${values.phoneNumber.replace(/\D/g, "")}`;
        const params = {
          ...values,
          phoneNumber: phoneNumber,
          orgId,
          priceId
        };
        // Form submission logic here
        mixpanel.track("form-page-viewed", {
          "form-name": "info",
        });
        mixpanel.track("form-started", params);
        console.log(params);
        navigate("/hahs/dob", { state: params });
      }}
      validate={(values: FormValues) => {
        const errors: Partial<FormValues> = {};

        const firstNameError = required(values.firstName);
        errors.firstName = firstNameError;

        const lastNameError = required(values.lastName);
        errors.lastName = lastNameError;

        const phoneNumberError = required(values.phoneNumber);
        errors.phoneNumber = phoneNumberError;

        const emailError = required(values.email) || email(values.email);
        errors.email = emailError;

        return errors;
      }}
      render={({ handleSubmit, submitError, valid }) => (
        <div className="m-8 overflow-clip rounded-3xl bg-white">
          <div className="flex flex-col">
            <div className="flex flex-col content-center justify-center gap-1 bg-white p-10 pb-2">
              <h1 className="text-caringwireDarkBlue mb-2 text-center text-3xl font-bold">
                Register for HAHS
              </h1>
              <p className="text-caringwireDarkBlue text-center text-xl font-light">
                The following information is used to setup your HAHS account and
                provide access.
              </p>
            </div>
            <div className="p-8">
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <InputField
                  name="firstName"
                  title="First Name"
                  placeholder="First Name *"
                  type="text"
                  autoComplete="given-name"
                  required
                />
                <InputField
                  name="lastName"
                  title="Last Name"
                  placeholder="Last Name *"
                  type="text"
                  autoComplete="family-name"
                  required
                />
                <PhoneNumberInput
                  name="phoneNumber"
                  title="Phone number"
                  required
                />
                <InputField
                  name="email"
                  title="Email"
                  placeholder="Email *"
                  type="email"
                  autoComplete="email"
                  required
                />

                {submitError && (
                  <div className="text-red-500">{FORM_ERROR}</div>
                )}
                <div className="mt-4 flex items-center justify-start">
                  <button
                    type="submit"
                    className={clsx(
                      "bg-caringwire inline-flex w-20 items-center justify-center px-4 py-2 font-bold text-white hover:bg-blue-700",
                      !valid && "opacity-20",
                    )}
                  >
                    <span>OK</span>
                  </button>
                  <span
                    className={clsx("ml-2 text-xs", !valid && "opacity-20")}
                  >
                    ⏎ press <span className="font-bold">Enter</span>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default InfoForm;
