import React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import AddressForm from "components/hahs/AddressForm";
import InfoForm from "components/hahs/InfoForm";
import DateOfBirthForm from "components/hahs/DateOfBirthForm";
import PaymentForm from "components/hahs/PaymentForm";
import SuccessPage from "components/hahs/Success";

export function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="hahs" element={<Layout />}>
            <Route index element={<InfoForm />} />
            <Route path="dob" element={<DateOfBirthForm />} />
            <Route path="address" element={<AddressForm />} />
            <Route path="success" element={<SuccessPage />} />
            <Route path="payment" element={<PaymentForm />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}
function Layout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page </Link>
      </p>
    </div>
  );
}
